import {
  BEGIN_GROUPS_FETCH,
  SUCCESS_GROUPS_FETCH,
  ADDING_NEW_GROUP,
  ADDED_NEW_GROUP,
  BEGIN_RESOURCES_FETCH,
  SUCCESS_RESOURCES_FETCH,
  ADDING_RESOURCE_TO_GROUP,
  ADDED_RESOURCE_TO_GROUP,
  CLEAN_SELECTED_GROUP,
  DELETING_RESOURCE,
  DELETED_RESOURCE,
  DELETING_GROUP,
  DELETED_GROUP,
  BEGIN_RESOURCE_FETCH,
  SUCCESS_RESOURCE_FETCH,
  UPDATING_RESOURCE,
  UPDATED_RESOURCE,
} from "../constants/ResourceGroupsConstants.js";
import axios from "axios";
import { Config } from "../configs";
import { createHashHistory } from "history";
import { showAlertAction } from "./AlertsActions";
import i18next from "i18next";

export const getGroups = () => { 
  return function (dispatch) {
    dispatch(beginGroupsFetch());


    return axios.get(Config.groupsUrl()
    // , {  
    //   headers: {AUTHORIZATION: localStorage.getItem("token")}
    // }
    )
      .then((response) => {
       
        if (parseInt(response.status / 200) === 1) {
          dispatch(successGroupsFetch(response.data.results));                                                                             
        } else {
          return dispatch(showAlertAction("Error", "error"));
        }
      })
     
      .catch((response) => {
        localStorage.setItem("token", "");
        localStorage.setItem("tenant", "");
        let history = createHashHistory();
        history.push("/login");
      });
  };
}

export const beginGroupsFetch = () => ({
  type: BEGIN_GROUPS_FETCH,
  payload: { groupsFetching: true },
});

export const successGroupsFetch = (groups) => ({  
  type: SUCCESS_GROUPS_FETCH,
  payload: { groupsFetching: false, groups },
});

export const addNewGroup = (name) => {
  return function (dispatch) {
    dispatch(addingNewGroup());

    return axios.put(Config.addGroupUrl(name), {}
    // , {  
    //   headers: {AUTHORIZATION: localStorage.getItem("token")}
    // }
    )  
    .then((response) => {
     
      if (parseInt(response.status / 200) === 1) {
        createHashHistory().push("/resource_groups");
        dispatch(successNewGroup());
      } else {
        return dispatch(showAlertAction("Error", "error"));
      }
    })
    .catch(() => {
      localStorage.setItem("token", "");
        localStorage.setItem("tenant", "");
        let history = createHashHistory();
        history.push("/login");
    });
};
};

export const addingNewGroup = () => ({
  type: ADDING_NEW_GROUP,
});

export const successNewGroup = () => {
  return function (dispatch) {
    dispatch(getGroups());
    dispatch(addedNewGroup());
  };
};

export const addedNewGroup = () => ({
  type: ADDED_NEW_GROUP,
});

export const getResource = (groupName, resourceId) => {
  return function (dispatch) {
    dispatch(beginResourceFetch(groupName));
    return axios.get(Config.resourcesUrl(groupName)
    // , {
    //   headers: { AUTHORIZATION: localStorage.getItem("token") },
    // }
    )
      .then((response) => {
       
        if (parseInt(response.status / 200) === 1) {
          response.data=response.data.find(resource => resource.email === resourceId)        
          dispatch(successResourceFetch(resourceId, response.data));
        } else {
          return dispatch(showAlertAction("Error", "error"));
        }
     
      })
      .catch(() => {
        localStorage.setItem("token", "");
        localStorage.setItem("tenant", "");
        let history = createHashHistory();
        history.push("/login");
      });
  };
};

export const beginResourceFetch = () => ({
  type: BEGIN_RESOURCE_FETCH,
  payload: { resourceFetching: true },
});

export const successResourceFetch = (resourceId, resource) => ({
  type: SUCCESS_RESOURCE_FETCH,
  payload: { resourceFetching: false, resourceId, resource },
});

export const getResources = (groupName) => {
  return function (dispatch) {
    dispatch(beginResourcesFetch(groupName));
    return axios.get(Config.resourcesUrl(groupName)
    // , {
    //   headers: { AUTHORIZATION: localStorage.getItem("token") },
    // }
    )
      .then((response) => {
        if (parseInt(response.status / 200) === 1) {
          dispatch(successResourcesFetch(groupName, response.data));
        } else {
          return dispatch(showAlertAction("Error", "error"));
        }
      })
      .catch(() => {
        localStorage.setItem("token", "");
        localStorage.setItem("tenant", "");
        let history = createHashHistory();
        history.push("/login");
      });
  };
};

export const beginResourcesFetch = (groupName) => ({
  type: BEGIN_RESOURCES_FETCH,
  payload: { selectedGroup: groupName, resourcesFetching: true },
});

export const successResourcesFetch = (groupName, array) => {
  return ({
    type: SUCCESS_RESOURCES_FETCH,
    payload: { resourcesFetching: false, resources:array, groupName:groupName },
  })
};

export const updateResource = (resourceId, groupName, resInfo) => {
  let {
    displayName,
    domain,
    email,
    description,
    l,

  } = resInfo
  let mail = `${email}@${domain}`
  const formData = new FormData();
  if (l !== "") { formData.set("l", l) } else {formData.set("l", " ")}
  if (description !== "") {formData.set("description", description)} else {formData.set("description", " ")};
  if (displayName !== "") {formData.set("displayName", displayName)} else {formData.set("displayName", " ")};
  if (mail !== "") {formData.set("email", mail)} else {formData.set("email", " ")};
  return function (dispatch) {
    dispatch(updatingResource());
    return axios.patch(Config.updateResourceUrl(groupName, mail), formData
    // , {
    //   headers: { AUTHORIZATION: localStorage.getItem("token") },
    // }
    )
      .then((response) => {
        if (parseInt(response.status / 200) === 1) {
          createHashHistory().push(`/resource_groups/${groupName}`);
          dispatch(updatedResource());
        } else {
          return dispatch(showAlertAction("Error", "error"));
        }
      })
      .catch(() => {
        localStorage.setItem("token", "");
        localStorage.setItem("tenant", "");
        let history = createHashHistory();
        history.push("/login");
      });
  };
};

export const updatingResource = () => ({
  type: UPDATING_RESOURCE,
});

export const updatedResource = () => ({
  type: UPDATED_RESOURCE,
});

export const addResourceToGroup = (l, displayName, description, groupName, email, domain) => {
  let mail = `${email}@${domain}`
  const formData = new FormData();
  if (l !== "") { formData.set("l", l) } else {formData.set("l", " ")}
  if (description !== "") {formData.set("description", description)} else {formData.set("description", " ")};
  if (displayName !== "") {formData.set("displayName", displayName)} else {formData.set("displayName", " ")};
  if (email !== "") {formData.set("email", `${email}@${domain}`)} else {formData.set("email", " ")};
  
  return function (dispatch) {
    return axios.put(Config.groupResourcesUrl(groupName, mail)
      , formData,
      // {
    //   headers: { AUTHORIZATION: localStorage.getItem("token") },
    // }
    )
      .then((response) => {
        if (parseInt(response.status / 200) === 1) {
          createHashHistory().push(`/resource_groups/${groupName}`);
          dispatch(addedResourceToGroup());
        }
      })
      .catch((response) => {
        if (response.status === 409) {           
           dispatch( showAlertAction(i18next.t("users_page.email_already_exists"), "error"));          
        }else if (response.status === 400 ) {          
          dispatch(showAlertAction(i18next.t("users_page.required_params"), "error"))
          // return dispatch({type: PREVENT_USER_ADD})   
        } else if (response.status === 401 ) {          
          dispatch(showAlertAction(i18next.t("users_page.old_token"), "error"))
          // return dispatch({type: PREVENT_USER_ADD})  
        } else if (response.status === 403 ) {          
          dispatch(showAlertAction(i18next.t("users_page.no_permission"), "error"))
          // return dispatch({type: PREVENT_USER_ADD})    
        } else {
          return dispatch(showAlertAction("Error", "error"));
        }
      });
  };
};

export const addingResourceToGroup = () => ({
  type: ADDING_RESOURCE_TO_GROUP,
  payload: { addingResourceToGroup: true },
});

export const addedResourceToGroup = () => ({
  type: ADDED_RESOURCE_TO_GROUP,
  payload: { addingResourceToGroup: false },
});

export const cleanSelectedGroup = () => ({
  type: CLEAN_SELECTED_GROUP,
  payload: { selectedGroup: "" },
});

export const deleteResource = (resourceMail, groupName) => {
  return function (dispatch) {
    dispatch(deletingResource());
    return axios.delete(Config.editDeleteResourceUrl(resourceMail, groupName)
    // , {
    //   headers: { AUTHORIZATION: localStorage.getItem("token") },
    // }
    )
      .then((response) => {
        dispatch(getResources(groupName));
        dispatch(deletedResource(resourceMail));
      })
      .catch((response) => {
        localStorage.setItem("token", "");
        localStorage.setItem("tenant", "");
        let history = createHashHistory();
        history.push("/login");
      });
  };
};

export const deletingResource = () => ({
  type: DELETING_RESOURCE,
  payload: { deletingResource: true },
});

export const deletedResource = (resourceMail) => ({
  type: DELETED_RESOURCE,
  payload: { deletingResource: false, resourceMail },
});

export const deleteGroup = (groupName) => {
  return function (dispatch) {
    dispatch(deletingGroup());
    return axios.delete(Config.deleteGroupUrl(groupName), {
      
    })
      .then((response) => {
        createHashHistory().push("/resource_groups");
        dispatch(successDeleteGroup(groupName));
      })
      .catch((response) => {
        localStorage.setItem("token", "");
        localStorage.setItem("tenant", "");
        let history = createHashHistory();
        history.push("/login");
      });
  };
};

export const successDeleteGroup = (groupName) => {
  return function (dispatch) {
    dispatch(getGroups());
    dispatch(deletedGroup(groupName));
  };
};

export const deletingGroup = () => ({
  type: DELETING_GROUP,
  payload: { deletingGroup: true },
});

export const deletedGroup = (groupName) => ({
  type: DELETED_GROUP,
  payload: { deletingGroup: false, groupName },
});

export const renameGroup = (groupName, newName) => {
  
  return function (dispatch) {
     
    return axios.patch(Config.renameGroupUrl(groupName, newName), 
    
    )
      .then((response) => {
        getGroups()
        createHashHistory().push("/resource_groups");
        dispatch(getGroups());
      })
      .catch((response) => {
        localStorage.setItem("token", "");
        localStorage.setItem("tenant", "");
        let history = createHashHistory();
        history.push("/login");
      });
  };
};

import React from "react";
import {
  TextField,
  Button,
  MenuItem,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  InputAdornment,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { addNewUser, getDomains } from "../../actions/UsersActions.js";
import { connect } from "react-redux";
import GoBack from "../../assets/back.svg";
import Trash from "../../assets/trashGreyDark.svg";
import Plus from "../../assets/plus.svg";
import GreyPlus from "../../assets/GreyPlus.svg";
import DarkTooltip from "../GeneralElements/DarkTooltip.js"
import { createHashHistory } from "history";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import AvatarUploader from "../Avatars/AvatarUploader.js";
import i18next from "i18next";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { showAlertAction } from "../../actions/AlertsActions";
import axios from "axios";
import { parser } from "../../allFunctions/parser.js"
import { phoneParser } from "../../allFunctions/parser.js"
import DeleteAlias from "./DeleteAlias.js"
import "../../styles/Users.css";

const mapStateToProps = (state) => ({
  domains: state.users.domains,
  domainsFetching: state.users.domainsFetching,
  addingNewUser: state.users.addingNewUser,
});

const mapDispatchToProps = (dispatch) => ({
  getDomains: () => dispatch(getDomains()),
  addNewUser: (userInfo) => dispatch(addNewUser(userInfo)),
});

const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    // minHeight: "100vh",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
  },
  infoForm: {
    marginBottom: "10px",
    "& > *": {
      margin: "10px",
    },
  },
  buttonsBlock: {
    "& > *": {
      margin: "10px",
    },
  },
  passHidden: {
    fontSize: "14px !important",
    fontFamily: "text-security-disc",
    "-webkit-text-security": "disc",
    "-moz-text-security": "disc",
    "text-security": "disc",
  },
  addUserBtn: {
    width: "168px",
    height: "32px",
    paddingLeft:"0px",
    marginLeft: "40px",
    color: "#19252E",
    // backgroundColor: "#F5F5F5",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.35px",
    border: "1px solid rgba(25, 37, 46, 0.4)",
    textTransform: "none",
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: "rgba(25, 37, 46, 0.08) !important",
      color: "#19252E",
      border: "1px solid rgba(25, 37, 46, 0.08)",
    },
    "&:disabled": {
      backgroundColor: "rgba(25, 37, 46, 0.08) !important",
      color: "#A3A8AB",
      border: "1px solid rgba(25, 37, 46, 0.08)",
      cursor: 'pointer !important',
    },
    // "&:selected": {
    //   backgroundColor: "#BFDDF3 !important",
    //   color: "#0079D0",
    //   // border: "1px solid rgba(25, 37, 46, 0.08)",
    // },
    // "&:active": {
    //   backgroundColor: "#BFDDF3 !important",
    //   color: "#0079D0",
    //   // border: "1px solid rgba(25, 37, 46, 0.08)",
    // },
  },
  RoundTooltip: {
    width: "32px",
    height: "32px",
    marginTop: "16px",
    "&:hover": {
      backgroundColor: "rgba(25, 37, 46, 0.08); !important",
    },
    "&:active": {
      backgroundColor: "rgba(25, 37, 46, 0.16); !important",
    },
  },
});

class NewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cn: "",
      sn: "",
      l: "",
      ou: "",
      domain: "",
      initials: "",
      title: "",
      description: "",
      telephoneNumber: "",
      labeledURI: "",
      info: "",
      homePhone: "",
      email: "",
      employeeNumber: 2,
      aliases: [],
      aliasIndex: [],
      aliasIndexWrongSymb: [],
      aliasIndexExtraDot: [],
      aliasError: false,
      aliasExtraDot: false,
      aliasMaxSymb: false,
      aliasWrongSymb: false,      
      aliasErrorMail: false,
      aliasDelete: false,
      indexOfAliasDelete: "",
      password: "",
      passwordConfirm: "",
      errorWindow: false,
      croppedAvatar: "",
      quota: 0,
      passwordError: false,
      smth: ""      
    };

    this.handleUserSubmit = this.handleUserSubmit.bind(this);
    this.handleAddAlias = this.handleAddAlias.bind(this);
    this.handleAvatarCrop = this.handleAvatarCrop.bind(this);
    this.handleAliasEmailChange = this.handleAliasEmailChange.bind(this);
    this.handleAliasDomainChange = this.handleAliasDomainChange.bind(this);
    this.handleRemoveAlias = this.handleRemoveAlias.bind(this);
    this.handleChangeRole = this.handleChangeRole.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleQuotaChange = this.handleQuotaChange.bind(this);
    this.history = createHashHistory();
    this.uniq_id = 0;
  }
  componentDidMount() {
    this.props.getDomains();
    localStorage.removeItem("aliases")    
  }
  componentDidUpdate(prevProps) {
    if(this.props.domains) {
      if (this.props.domains !== prevProps.domains) {
        this.setState({ domain: this.props.domains[0].domain });
      }
    }
  }

  async handleAvatarCrop(avatarUrl) {
    let avatarBlob = await fetch(avatarUrl).then((r) => r.blob());
    this.setState({ croppedAvatar: avatarBlob });
  }
  
  handleQuotaChange(e) {
    e.preventDefault()
    let valueX = e.target.value;
    if (valueX.match(/^\d*[,/.]?\d*$/)) {
      valueX = valueX.replace(/,/g, ".");
      valueX = valueX.replace(/\//g, "");
      this.setState({ quota: valueX });
    }
  }

  handleUserSubmit(e) {
    e.preventDefault();
    if (this.state.password !== this.state.passwordConfirm) {
      this.setState({ passwordError: true })
      return;
    }else this.props.addNewUser(this.state);
 
  }
  handleAliasEmailChange(e, index) {
    e.preventDefault();   
    let value = e.target.value;
    if (localStorage.getItem("aliases") && localStorage.getItem("aliases").includes(value)) {
      localStorage.removeItem("aliases")
    }
    const tester = /^[0-9A-Z_a-z.]+$/   
    let validated = value.match(tester);
    
    if (!validated & value !== "") {
      this.state.aliasIndexWrongSymb.push(index)
      this.setState({ aliasWrongSymb: true })
    } else {
      this.setState({ aliasWrongSymb: false })
      this.setState({aliasIndexWrongSymb: this.state.aliasIndexWrongSymb.filter(e => e !== index)}) 
    }
    

    if (value.length > 255) {
      this.setState({ aliasMaxSymb: true })    
    } else {
      this.setState({ aliasMaxSymb: false })
    }  
      let currentAliases = this.state.aliases;
      currentAliases[index].email = value;
      this.setState(currentAliases);   
    }

  handleAliasEmailBlur(e, index) {
    e.preventDefault();
    let value = e.target.value;        
    if (value.length === 0) {
      this.state.aliasIndex.push(index)
      this.setState({ aliasError: true })    
    } else {
      this.setState({ aliasError: false })
    }
    if ((/^\.{1,}/g).test(value) || (/\.{1,}$/g).test(value) || (/\.{2,}/g).test(value) & value !== "") {
      this.state.aliasIndexExtraDot.push(index)
      this.setState({ aliasExtraDot: true })
    } else {
      this.setState({ aliasExtraDot: false })
      this.setState({aliasIndexExtraDot: this.state.aliasIndexExtraDot.filter(e => e !== index)})       
    }
  }
  handleAliasDomainChange(e, index) {
    e.preventDefault();
    let value = e.target.value;
    let currentAliases = this.state.aliases;
    currentAliases[index].domain = value;
    this.setState(currentAliases);
  }
  handleRemoveAlias(e, index) {
    e.preventDefault();
    let currentAliases = this.state.aliases;
    currentAliases.splice(index, 1);
    this.setState({ aliases: currentAliases });
  }
  handleAddAlias(e) {
    e.preventDefault();
    let currentAliases = this.state.aliases;
    let domains = this.props.domains;
    let indexedDomains = domains.map((el) => {
      this.uniq_id += 1;
      return { key: this.uniq_id + el.domain, value: el.domain };
    });
    currentAliases.push({
      domains: indexedDomains,
      key: Date.now() + "",
      domain: indexedDomains[0].value,
      email: "",
    });
    this.setState({ aliases: currentAliases });
  }

  
  handleChangeRole = (event) => {
    this.setState({ role: event.target.value });
  };

  handleClickOpen = () => {
    this.setState({ errorWindow: true });
  };

  handleClose = () => {
    this.setState({ errorWindow: false });
  };

  nameChangeHandler = (e, v) => {    
    if (e.target.value.match(/[^a-zA-Zа-яА-Я0-9_.-\s+]/g)) return
    if (e.target.value.length > 255) return
    else if (v=== "name") this.setState({ cn: parser(e.target.value) })
    else if (v=== "surName") this.setState({ sn: parser(e.target.value) })
    else if (v=== "middleName") this.setState({ initials: parser(e.target.value) })
    else if (v=== "department") this.setState({ ou: parser(e.target.value) })
    else if (v=== "position") this.setState({ title: parser(e.target.value) })
    else if (v === "city") this.setState({ l: parser(e.target.value) })
    else if (v=== "description") this.setState({ description: parser(e.target.value) })
  }

  phoneChangeHandler = (e, v) => {    
    if (e.target.value.match(/[^/\+/0-9-()\s+]/g)) return
    if (e.target.value.length > 255) return
    else if (v=== "telephoneNumber") this.setState({ telephoneNumber: phoneParser(e.target.value).trim() })
    else if (v=== "homePhone") this.setState({ homePhone: phoneParser(e.target.value).trim() })
    
  }
  // /(?:\+|\d)[\d\-\(\) ]{9,}\d/g


  render() {
    let classes = this.props.classes;
    return (
      <div className={classes.root}>
        {this.props.addingNewUser ? (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: "100vh" }}
          >
            <Grid item xs={3}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <div
            style={{
              paddingBottom: "10px",
              paddingTop: "10px",
              // overflow: "scroll",
              // maxHeight: "100vh",
            }}
          >
            <div className="backAndDelete">
              <div className="newUserForm">
                <Tooltip title={i18next.t("back")}>
                  <Button
                    className="backBtnIcon"
                    startIcon={<ArrowBackIcon color="primary" />}
                    onClick={(e) => this.history.push("/users")}
                  >
                    {i18next.t("new_user")}
                  </Button>
                </Tooltip>
              </div>
            </div>

            <div className="mainInfoAva">
              <div className="avaAndLogin">
                <AvatarUploader onAvatarCrop={this.handleAvatarCrop} />
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className="userTableCell">
                        <div className="loginRaw">
                          <TextField
                            label={i18next.t("login")}
                            className="loginField"
                            value={this.state.email}
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                          />
                          <div>
                            <div className="mainEmailLabel">@</div>
                          </div>
                          <div
                            style={{
                              color: "rgba(22, 33, 41, 0.56)",
                              display: "inherit",
                              padding: 0,
                              fontSize: "12px",
                              width: "216px",
                            }}
                          >
                            <TextField
                              select
                              className="domainCell"
                              value={this.state.domain ? this.state.domain : ""}
                              disabled={this.props.domainsFetching}
                              onChange={(e) =>
                                this.setState({ domain: e.target.value })
                              }
                            >
                              {this.props.domains ? (
                                this.props.domains.map((option, key) => (
                                  <MenuItem
                                    className="options"
                                    key={"-1" + key}
                                    value={option.domain}
                                  >
                                    {option.domain}
                                  </MenuItem>
                                ))
                              ) : (
                                <div></div>
                              )}
                            </TextField>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="userTableCell">
                        <div className="roleRow">
                          <TextField
                            label={i18next.t("role")}
                            select
                            className="domainCell"
                            value={this.state.employeeNumber}
                            onChange={(e) =>
                              this.setState({ employeeNumber: e.target.value })
                            }
                          >
                            {[
                              { key: 2, value: i18next.t("user") },
                              { key: 1, value: i18next.t("admin") },
                            ].map((option) => (
                              <MenuItem
                                className="options"
                                key={option.key}
                                value={option.key}
                              >
                                {option.value}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            className="domainCell"
                            label={i18next.t("quota")}
                            name="quota"
                            // autoComplete="cc-number"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  Mb
                                </InputAdornment>
                              ),
                            }}
                            value={this.state.quota}
                            onChange={this.handleQuotaChange}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>

              <div className="row">
                <div className="addNewUserForm">{i18next.t("password")}</div>
                <div className="changeConfirmPassword">
                  <TextField
                    style={{ marginRight: "20px" }}
                    InputProps={{
                      classes: { input: classes.passHidden },
                    }}
                    value={this.state.password}
                    label={i18next.t("new_password")}
                    // name="password"
                    error={this.state.passwordError}
                    helperText={
                      this.state.passwordError && i18next.t("password_mismatch")
                    }
                    onChange={(e) =>
                      this.setState({
                        password: e.target.value,
                        passwordError: false,
                      })
                    }
                  />

                  <TextField
                    label={i18next.t("confirm_password")}
                    InputProps={{ classes: { input: classes.passHidden } }}
                    error={this.state.passwordError}
                    value={this.state.passwordConfirm}
                    onChange={(e) =>
                      this.setState({
                        passwordConfirm: e.target.value,
                        passwordError: false,
                      })
                    }
                  />
                  {/* {this.state.errorWindow && (
                    <div className="help-block">
                      {i18next.t("confirm_password")}
                    </div>
                  )} */}
                </div>
              </div>

              <div>
                <div
                  className="addNewUserForm"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div style={{ marginTop: "8px" }}>
                    {i18next.t("alias.aliases")}
                  </div>
                    {this.state.aliases.length === 10 ?
                      <DarkTooltip
                        title={
                          <>
                          {i18next.t("alias.max_aliases")}
                          </>
                        }                      
                      >
                        <div>
                        <Button
                          disableRipple
                          disabled={this.state.aliases.length === 10}
                          className={this.props.classes.addUserBtn}                          
                          onClick={this.handleAddAlias}
                        >
                          <div style={{ paddingLeft: "0px", display: "flex" }}>
                              <img src={this.state.aliases.length === 10 ? GreyPlus : Plus} alt={Plus} />
                            <div style={{ marginLeft: "10px" }}>
                              {i18next.t("alias.add_mail")}
                            </div>
                          </div>
                        </Button>
                        </div>
                      </DarkTooltip>
                      :
                      <Button
                        disableRipple                       
                        className={this.props.classes.addUserBtn}                        
                        onClick={this.handleAddAlias}
                      >
                        <div style={{ paddingLeft: "0px", display: "flex" }}>
                          <img src={Plus} alt={Plus} />
                          <div style={{ marginLeft: "10px" }}>
                            {i18next.t("alias.add_mail")}
                          </div>
                        </div>
                      </Button>
                    }
                </div>

                {this.state.aliases.map((alias, index) => (
                  <div>
                    <DeleteAlias
                        open={this.state.aliasDelete}
                        setOpen={(v) => this.setState({aliasDelete:v})}
                        title={i18next.t("alias.remove_title")}
                        body={i18next.t("alias.remove_body")}                       
                        mainButton={{
                          action: (e) => {
                            this.handleRemoveAlias(e, this.state.indexOfAliasDelete) 
                            this.setState({aliasDelete:false})
                          },
                        }}
                      />
                    <div className="loginRaw" style={{marginTop: "36px"}}>
                      <TextField
                        label={i18next.t("alias.label")}
                        className="loginField"
                        value={alias.email}
                        onChange={(e) => this.handleAliasEmailChange(e, index)}
                        onBlur={(e) => this.handleAliasEmailBlur(e, index)}
                      />
                      <div style={{ marginLeft: "16px", marginTop: "22px" }}>
                        @
                      </div>

                      <div
                        style={{
                          color: "rgba(22, 33, 41, 0.56)",
                          display: "inherit",
                          padding: "0",
                          fontSize: "12px",
                          width: "320px",
                          marginLeft: "16px",
                        }}
                      >
                        <TextField
                          select
                          className="domainCell"
                          style={{ paddingRight: 0 }}
                          value={alias.domain}
                          disabled={this.props.domainsFetching}
                          onChange={(e) =>
                            this.handleAliasDomainChange(e, index)
                          }
                        >
                          {alias.domains.map((option, key) => (
                            <MenuItem
                              className="options"
                              key={option.key}
                              value={option.value}
                            >
                              {option.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                      
                      <Tooltip
                        title={i18next.t("alias.remove")}
                        style={{ marginLeft: "30px" }}
                      >
                        <IconButton
                          aria-label="Remove"
                          onClick={(e) => this.setState({aliasDelete: true, indexOfAliasDelete:index})}
                          className={this.props.classes.RoundTooltip}
                        >
                          <img src={Trash} alt={Trash} />
                        </IconButton>
                      </Tooltip>
                      
                    </div>
                    <div
                      className="loginFieldError"
                      style={{
                        display:
                          this.state.aliases[index].email.length > 255
                            ? "block"
                            : "none",
                      }}
                    >
                      {i18next.t("alias.max_symb")}
                    </div>
                    <div
                      className="loginFieldError"
                      style={{
                        display:
                          this.state.aliasIndexWrongSymb.includes(index) &&
                          this.state.aliases[index].email !== ""
                            ? "block"
                            : "none",
                      }}
                    >
                      {i18next.t("alias.wrong_symb")}
                    </div>
                    <div
                      className="loginFieldError"
                      style={{
                        display:
                          this.state.aliases[index].email === "" &&
                          this.state.aliasIndex.includes(index)
                            ? "block"
                            : "none",
                      }}
                    >
                      {i18next.t("alias.empty_field")}
                    </div>
                    <div
                      className="loginFieldError"
                      style={{
                        display: this.state.aliasIndexExtraDot.includes(index)
                          ? "block"
                          : "none",
                      }}
                    >
                      {i18next.t("alias.alias_extra_dot")}
                    </div>
                    <div
                      className="errorAlias"
                      style={{
                        display: localStorage.getItem("aliases") && localStorage.getItem("aliases").includes(alias.email)
                          ? "block"
                          : "none",
                      }}
                    >
                      {i18next.t("alias.error")}
                    </div>
                  </div>
                ))}
              </div>

              <div className="emailDomain"></div>
              <div className="addNewUserForm">
                {i18next.t("personal_information")}
              </div>
              <div className="row">
                <TextField
                  className="firstNameCell"
                  label={i18next.t("name")}
                  value={this.state.cn}
                  inputProps={{
                    autocomplete: "disabled",
                    type: "text",
                  }}
                  onChange={(e) => {
                    this.nameChangeHandler(e, "name");
                  }}
                />
                <TextField
                  className="secondNameCell"
                  label={i18next.t("surname")}
                  value={this.state.sn}
                  inputProps={{
                    autocomplete: "disabled",
                    type: "text",
                  }}
                  onChange={(e) => {
                    this.nameChangeHandler(e, "surName");
                  }}
                />
              </div>
              <div className="row">
                <TextField
                  className="patronymicCell"
                  label={i18next.t("middle_name")}
                  value={this.state.initials}
                  inputProps={{
                    autocomplete: "disabled",
                    type: "text",
                  }}
                  onChange={(e) => {
                    this.nameChangeHandler(e, "middleName");
                  }}
                />
                <TextField
                  className="companyCell"
                  label={i18next.t("department")}
                  value={this.state.ou}
                  inputProps={{
                    autocomplete: "disabled",
                    type: "text",
                  }}
                  onChange={(e) => {
                    this.nameChangeHandler(e, "department");
                  }}
                />
              </div>
              <div className="row">
                <TextField
                  className="positionCell"
                  label={i18next.t("position")}
                  value={this.state.title}
                  inputProps={{
                    autocomplete: "disabled",
                    type: "text",
                  }}
                  onChange={(e) => {
                    this.nameChangeHandler(e, "position");
                  }}
                />
                <TextField
                  className="descriptionCell"
                  label={i18next.t("description")}
                  value={this.state.description}
                  inputProps={{
                    autocomplete: "disabled",
                    type: "text",
                  }}
                  onChange={(e) => {
                    this.nameChangeHandler(e, "description");
                  }}
                />
              </div>
              <div className="row">
                <TextField
                  className="cityCell"
                  label={i18next.t("city")}
                  value={this.state.l}
                  inputProps={{
                    autocomplete: "disabled",
                    type: "text",
                  }}
                  onChange={(e) => {
                    this.nameChangeHandler(e, "city");
                  }}
                />
              </div>
              <div className="contacts">{i18next.t("contacts")}</div>
              <div className="allContacts">
                <div className="contactBlock">
                  <div className="contactRow">
                    <TextField
                      className="contactCell"
                      label={i18next.t("work_phone")}
                      value={this.state.telephoneNumber}
                      inputProps={{
                        autocomplete: "disabled",
                        type: "text",
                      }}
                      onChange={(e) => {
                        this.phoneChangeHandler(e, "telephoneNumber");
                      }}
                    />
                  </div>
                  <div className="contactRow">
                    <TextField
                      className="contactCell"
                      label={i18next.t("home_phone")}
                      value={this.state.homePhone}
                      inputProps={{
                        autocomplete: "disabled",
                        type: "text",
                      }}
                      onChange={(e) => {
                        this.phoneChangeHandler(e, "homePhone");
                      }}
                    />
                  </div>
                  <div className="contactRow">
                    <TextField
                      className="contactCell"
                      label="Skype"
                      value={this.state.info}
                      inputProps={{
                        autocomplete: "disabled",
                        type: "text",
                      }}
                      onChange={(e) => this.setState({ info: e.target.value })}
                    />
                  </div>
                </div>
                <div className="contactBlock">
                  {/* <div className="contactRow">
                    <TextField
                      className="contactCell"
                      label="Facebook"
                      value={this.state.labeledURI}
                      inputProps={{
                        autocomplete: "disabled",
                        type: "text",
                      }}
                      onChange={(e) =>
                        this.setState({ labeledURI: e.target.value })
                      }
                    />
                  </div>
                  <div className="contactRow">
                    <TextField
                      className="contactCell"
                      label="Skype"
                      value={this.state.info}
                      inputProps={{
                        autocomplete: "disabled",
                        type: "text",
                      }}
                      onChange={(e) => this.setState({ info: e.target.value })}
                    />
                  </div> */}
                </div>
              </div>
              <div className="addButton">
                <Button
                  onClick={this.handleUserSubmit}
                  className="addButtonBtn"
                  variant="outlined"
                  disabled={
                    !this.state.cn ||
                    !this.state.sn ||
                    !this.state.email ||
                    !this.state.quota ||
                    this.state.aliasError ||
                    this.state.aliasMaxSymb ||
                    this.state.aliasWrongSymb
                    // !this.state.password ||
                    // !this.state.passwordConfirm
                  }
                >
                  {i18next.t("add")}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(NewUser)
);

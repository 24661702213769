import React from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from '@material-ui/core';

const styless = makeStyles(theme => ({
	arrow: {
		display: 'none',
	},
	tooltip: {
		background: '#19252E',
		boxShadow: '0px 4px 8px -4px rgba(25, 37, 46, 0.36), 0px 8px 8px -2px rgba(25, 37, 46, 0.16), 0px 0px 8px rgba(25, 37, 46, 0.12)',
		borderRadius: '2px',
		padding: '12px 8px',
		fontFamily: 'PT Sans',
		fontSize: '14px',
		lineHeight: '20px',
		whiteSpace: 'pre-line',
		color: 'rgba(255, 255, 255, 0.92)',
		'&>a': {
			color: 'inherit',
			textDecoration: 'underline',
			cursor: 'pointer',
			'&:hover': {
				textDecoration: 'underline',
			},
		},
	},
}));

const hiddenStyle = makeStyles(theme => {
	return {
		arrow: {
			display: 'none',
		},
		tooltip: {
			display: 'none',
		},
	};
});

const DarkTooltip = props => {
	const classes = props.hidden ? hiddenStyle() : styless();
	return <Tooltip arrow placement={props.placement || 'bottom'} title={props.title} classes={classes} {...props} />;
};

export default DarkTooltip;
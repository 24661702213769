import {
  BEGIN_MAILLISTS_FETCH,
  SUCCESS_MAILLISTS_FETCH,
  ADDING_NEW_MAILLIST,
  ADDED_NEW_MAILLIST,
  CLEAN_SELECTED_MAILLIST,
  DELETED_MAILLIST,
  DELETING_MAILLIST,
  SELECTED_MAILLIST,
  UPDATED_MAILLIST,
  UPDATING_MAILLIST,
} from "../constants/MaillistsConstants";
import { SUCCESS_USERS_FETCH } from "../constants/UsersConstants";
import axios from "axios";
import { Config } from "../configs";
import { createHashHistory } from "history";
import { showAlertAction } from "./AlertsActions";
import { validate } from "../components/Validations/EmailValidator";
import i18next from "i18next";

export const getMaillists = () => {
  return function (dispatch) {
    dispatch(beginMaillistsFetch());
    return axios
      .get(Config.maillistsUrl(), {
        // headers: {AUTHORIZATION: localStorage.getItem("token")}
      })
      .then((response) => {        
        if (parseInt(response.status / 200) === 1) {
          dispatch(successMaillistsFetch(response.data.results));
        } else {
          return dispatch(showAlertAction("Error", "error"));
        }
      });

    // .catch((response) => {
    //   localStorage.setItem("token", "");
    //   localStorage.setItem("tenant", "");
    //   let history = createHashHistory();
    //   history.push("/login");
    // });
  };
};

export const beginMaillistsFetch = (displayName) => ({
  type: BEGIN_MAILLISTS_FETCH,
  payload: { maillistsFetching: true },
});

export const getSelectedMaillist = (displayName) => ({
  type: SELECTED_MAILLIST,
  payload: displayName,
});

export const successMaillistsFetch = (maillists) => ({
  type: SUCCESS_MAILLISTS_FETCH,
  payload: { maillistsFetching: false, maillists },
});

export const addNewMaillist = (mail, alias, displayName) => {
  return function (dispatch) {
    dispatch(addingNewMaillist());
    const formData = new FormData();
    const preAliases = alias.map((al) => `"${al}"`);
    const aliases = `[ ${preAliases.join(",")} ]`;
    formData.append("tenant-id", localStorage.getItem("tenant"));
    formData.append("mail", mail);
    formData.append("alias", aliases);

    formData.append("displayName", displayName);

    if (
      !validate(`${mail}`) ||      
      mail.match(
        /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g
      )
    ) {
      dispatch(showAlertAction(i18next.t("mailLists.invalid_mail_value"), "error"));
      return;
    }
    if (`${mail}`.length > 255)
    {
      dispatch(showAlertAction(i18next.t("mailLists.invalid_mail_limit"), "error"));
      return;
    }


    return axios
      .put(Config.newMaillistsUrl(mail), formData)
      .then((response) => {       
        if (parseInt(response.status / 200) === 1) {
          dispatch(addedNewMaillist());
          dispatch(getMaillists());
          let history = createHashHistory();
          history.push("/maillists");
        }      
      })
      .catch((response) => {
        if (response.status === 400 ) {          
          dispatch(showAlertAction(i18next.t("users_page.required_params"), "error"))
          // return dispatch({type: PREVENT_USER_ADD})   
        } else if (response.status === 401 ) {          
          dispatch(showAlertAction(i18next.t("users_page.old_token"), "error"))
          // return dispatch({type: PREVENT_USER_ADD})  
        } else if (response.status === 403 ) {          
          dispatch(showAlertAction(i18next.t("users_page.no_permission"), "error"))
          // return dispatch({type: PREVENT_USER_ADD})    
        }
        else {
          return dispatch(showAlertAction("Error", "error"));
        }  
        // localStorage.setItem("token", "");
        // localStorage.setItem("tenant", "");
        // let history = createHashHistory();
        // history.push("/login");
      });
  };
};

export const addingNewMaillist = () => ({
  type: ADDING_NEW_MAILLIST,
});

export const addedNewMaillist = () => ({
  type: ADDED_NEW_MAILLIST,
});

export const cleanSelectedMaillist = () => ({
  type: CLEAN_SELECTED_MAILLIST,
  payload: { selectedMaillist: "" },
});
export const deleteMaillist = (maillistEmail, aliasTodel) => {
  const formData = new FormData();
  if (aliasTodel) {
    formData.append("mail", maillistEmail);
    formData.append("aliases", aliasTodel);
  } else {
    formData.append("mail", maillistEmail);
  }
  return function (dispatch) {
    dispatch(deletingMaillist());
    axios
      .delete(Config.newMaillistsUrl(maillistEmail), {
        // headers: { AUTHORIZATION: localStorage.getItem("token") },
        // body: formData,
      })
      .then((response) => {
        dispatch(deletedMaillist(maillistEmail));
        dispatch(getMaillists());
        let history = createHashHistory();
        history.push("/maillists");
      })
      .catch((response) => {
        localStorage.setItem("token", "");
        localStorage.setItem("tenant", "");
        let history = createHashHistory();
        history.push("/login");
      });
  };
};

export const deletingMaillist = () => ({
  type: DELETING_MAILLIST,
  payload: { deletingMaillist: true },
});

export const deletedMaillist = (maillistEmail) => ({
  type: DELETED_MAILLIST,
  payload: { deletingMaillist: false, maillistEmail },
});

export const successUsersFetch = (users, usersPagesCount, page) => ({
  type: SUCCESS_USERS_FETCH,
  payload: { usersFetching: false, users, usersPagesCount, page },
});

export const updateMaillist = (mail, displayName, newmail, alias, oldAlias) => {
  // let add = alias.filter( el => oldAlias.indexOf( el ) < 0 );
  // let del = oldAlias.filter( el => alias.indexOf( el ) < 0 );

  return function (dispatch) {
    dispatch(updatingMaillist());

    const formData = new FormData();

    // formData.append("tenant-id", localStorage.getItem("tenant"));

    formData.append("displayName", displayName);
    formData.append("newmail", newmail);
    // const preAliases = alias.map((al) => `"${al}"`);
    // const aliases = `[ ${preAliases.join(",")} ]`;
    // const aliases = `[ ${alias.join(",")} ]`;
    // formData.append("alias", JSON.stringify(alias));
    formData.append("alias", alias);
    if (
      !validate(`${newmail}`) ||      
      newmail.match(
        /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g
      )
    ) {
      dispatch(showAlertAction(i18next.t("mailLists.invalid_mail_value"), "error"));
      return;
    }
    if (`${newmail}`.length > 255)
    {
      dispatch(showAlertAction(i18next.t("mailLists.invalid_mail_limit"), "error"));
      return;
    }
    // if(mail !== newmail) {
    //   formData.append("newmail", newmail);
    // } else  formData.append("mail", mail);

    // if (del.length > 0) {
    //   dispatch(deleteMaillist(mail, JSON.stringify(del)))
    //   if (mail === newmail && add.length === 0) return
    // }

    return axios
      .patch(
        Config.newMaillistsUrl(mail),
        // headers: { AUTHORIZATION: localStorage.getItem("token") }
        formData
      )
      .then(() => {
        dispatch(getMaillists());

        dispatch(updatedMaillist());
      })
      .catch(() => {
        localStorage.setItem("token", "");
        localStorage.setItem("tenant", "");
        let history = createHashHistory();
        history.push("/login");
      });
  };
};

export const updatingMaillist = () => ({
  type: UPDATING_MAILLIST,
});
export const updatedMaillist = () => {
  createHashHistory().push("/maillists");
  return { type: UPDATED_MAILLIST };
};
